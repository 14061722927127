<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="真实姓名" v-model="searchParam.realName" />
        <v-input label="用户昵称" v-model="searchParam.nickName" />
        <v-input label="用户手机" v-model="searchParam.mobile" />
        <!-- <v-select
          clearable
          placeholder="请选择"
          :options="cardTypeOps"
          v-model="searchParam.idCardType"
          @change="$refs.list.search()"
          label="身份证件类型"
        /> -->
        <v-input label="身份证件号码" v-model="searchParam.idCardNum" />
        <v-select
          clearable
          placeholder="请选择"
          :options="auditStatusOps"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          :text="scope.row.auditStatus === 0 ? '审核' : '查看'"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          v-if="
            scope.row.rfidNum != scope.row.houseNum &&
            scope.row.auditStatus == 1
          "
          text="手动上报人房"
          type="text"
          @click="report(scope.row.userId)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getRealnameAuditListURL,
  getInitInfoURL,
  manualReportUserSpaceRelation,
} from "./api.js";
import { auditStatusMap, setAuditStatusOps, sexMap, setSexOps } from "./map.js";
// import {
//   createAlinkVNode,
//   createImgVNode,
//   createHTMLVNode,
// } from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      searchParam: {
        realName: "",
        nickName: "",
        mobile: "",
        idCardNum: "",
        idCardType: "",
        auditStatus: 0,
      },
      cardTypeOps: [],
      auditStatusOps: setAuditStatusOps(),
      setSexOps: setSexOps(),
      tableUrl: getRealnameAuditListURL,
      headers: [
        {
          prop: "mobile",
          label: "手机号码",
        },
        {
          prop: "nickName",
          label: "用户昵称",
        },
        {
          prop: "realName",
          label: "真实姓名",
        },
        {
          prop: "gender",
          label: "性别",
          formatter: (row, prop) => {
            return sexMap[row[prop]] || "--";
          },
        },
        {
          prop: "idCardType",
          label: "身份证件类型",
        },
        {
          prop: "idCardNum",
          label: "身份证号码",
        },
        {
          prop: "rfidNum",
          align: "center",
          label: "已上报房屋数量/房屋数量",
          formatter: (row, prop) => {
            return `${row.rfidNum}/${row.houseNum}`;
          },
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter(row, prop) {
            // 0:待审核 1:审核通过 2:审核驳回
            return auditStatusMap[row[prop]] || "--";
          },
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {
    // this.getInitInfo();
  },
  methods: {
    // 上报
    report(userId) {
      this.$confirm("是否手动上报人房?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确认",
      }).then(() => {
        this.$axios
          .post(manualReportUserSpaceRelation, { userId })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.$refs.list.getList();
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "realNameAuditForm",
        query: {
          isView: false,
        },
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "realNameAuditForm",
        query: {
          id: row.id,
        },
      });
    },
    getInitInfo() {
      this.cardTypeOps = [];
      this.$axios.get(`${getInitInfoURL}`).then((res) => {
        if (res.code === 200) {
          res.data.idCardTypeList.forEach((item) => {
            item.label = item.dictValue;
            item.value = item.dictKey;
            this.cardTypeOps.push(item);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
