import { mapHelper } from "@/utils/common.js";

// 审核状态 0:待审核 1:审核通过 2:审核驳回
const auditStatus = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
];

const { map: auditStatusMap, setOps: setAuditStatusOps } =
  mapHelper.setMap(auditStatus);

// 性别 F：女性 M：男性
const sex = [
  {
    value: "F",
    label: "女性",
  },
  {
    value: "M",
    label: "男性",
  },
];

const { map: sexMap, setOps: setSexOps } = mapHelper.setMap(sex);

export { auditStatusMap, setAuditStatusOps, sexMap, setSexOps };
