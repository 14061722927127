const getRealnameAuditListURL = `/gateway/blade-user/user/getRealnameAuditList`;
const getRealnameAuditDetailURL = `/gateway/blade-user/user/getRealnameAuditDetail`;
const examineRealnameAuditURL = `/gateway/blade-user/user/examineRealnameAudit`;
const getInitInfoURL = `/gateway/blade-system/userManagement/getInitInfo`;
const addRoomUrl = `/gateway/hc-mini/user/mini/register`;
//上报
const manualReportUserSpaceRelation = `/gateway/hc-space/space/manualReportUserSpaceRelation`;
export {
  getRealnameAuditListURL,
  getRealnameAuditDetailURL,
  examineRealnameAuditURL,
  getInitInfoURL,
  addRoomUrl,
  manualReportUserSpaceRelation,
};
